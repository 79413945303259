.age-gate-modal-backdrop-image {
  background-size: cover !important;
  background-position: center center !important;
}

.modal-background-video {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

.age-gate-modal-backdrop-video.show {
  opacity:1!important;
}

.age-gate-modal {
  background: rgba(53, 50, 47, .75);
  z-index:999999;
  .modal-content {
    border-radius: 3px;
    background-color:transparent;
    border:0px;
  }

  .age-gate-modal-logo {
    img {
      width: auto;
      margin-top: 30px;
      height: 60px;
      margin-left: 30px;

      @include media-breakpoint-up(md) {
        margin-left: 75px;
        height: 147px;
      }
    }
  }

  .h5 {
    font-weight: bold;
  }

  .modal-header {
    border: 0;
  }

  .modal-footer {
    border: 0;
    font-family: $paragraph-font;
    justify-content: start;
    .primary-btn {
      border: 0px;
    }
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
    font-family: $paragraph-font;
  }

  .age-gate-denied-body {
    display: none;
  }
}
