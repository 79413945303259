/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
#gform_submit_button_1, #gform_submit_button_4 {
  @extend .primary-btn;
  @extend .white-btn;
}
/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  border: 0px;

  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper.gravity-theme .gfield input, .gform_wrapper.gravity-theme .gfield select.large, textarea,
.gform_wrapper .gfield input, .gform_wrapper .gfield select {
  background: #fff!important;
  border: solid 1px #ccc!important;
  outline: none!important;
  border-radius: 20px!important;
  padding: 5px 15px!important;
}

input#gform_submit_button_5 {
  border: 0;
}

/* newsletter */
#gform_2 {
  input#input_2_1 {
    border-radius: 100px!important;
    border: solid 1px #ccc!important;
    padding: 15px 20px!important;
    background: #F5F5F5!important;
  }
  .gform_body.gform-body {
    position: relative;
    margin-top: 15px;
  }
  label.gfield_label {
    display: none!important;
  }
  input#gform_submit_button_2 {
    background: #343434;
    border-radius: 100px;
    border: 0px;
    color: #fff;
    height: 50px;
    width: 50px;
    padding: 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    /*&:after {
      content: "→";
      color: #fff!important;
      height: 50px;
      width: 50px;
      top: 0px;
      left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }*/
  }
}
.gform_wrapper.gravity-theme #gform_2 .gform_footer {
  margin: 0px;
  padding: 0px;
  top: -56px;
  position: relative;
  left: calc(100% - 55px);
  width: 53px;
}
