// Theme Colors/Branding
$brand-primary: #38523E;
$brand-primary-rgb: 20,89,186;
$brand-primary-offset: #939888;
$brand-secondary: #7ab317;
$dark: #343434;
$light: #FCF7EE;
$medium: #FCF7EE;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #B17C3A;
$accent-2: #E2B86D;
$body-font: #343434;
$error: #d81e00;