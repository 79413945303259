.rpb-accordion {
  .accordion-item {
    .accordion-body {
      p {
        &:last-of-type {
          margin-bottom:0;
        }
      }
    }
    .accordion-button:not(.collapsed) {
      background-color:$brand-primary;
      color:white;
      font-size: 1.25em;
      outline: none!important;
    }
    .accordion-button.collapsed {
      font-size: 1.25em;
      color: $brand-primary;
    }
    .accordion-button, .accordion-button:hover, .accordion-button:focus {
      outline: none!important;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");;
    }
  }
}