body {
  background: #fff;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */
.quick-card-bkg-grow {
  background-size:75%;
  background-position:center;
  background-repeat: no-repeat;
  min-height:200px;
  transition:all .2s ease-out;
}
.quick-card:hover .quick-card-bkg-grow {
  background-size:100%;
  transition:all .2s ease-out;
}
.quick-card:hover .quick-card-title-hover {
  background-color: $brand-primary!important;
  transition:all .2s ease-out;

  a {
    color: #fff!important;
  }
}
.quick-cards .icon {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background:$brand-primary;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  img {
    height: 25px;
    width: auto;
    filter: brightness(0) invert(1);
  }
}
.sidebar-deals .icon {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background: #38523E;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  padding: 0px;
}
.sidebar-deals .splide__arrow {
  top: 35%;
}
.leaf-left-before{
  position: relative;

  &:before {
    content: '';
    background:url(../images/leaf.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(32deg);
    left: 0px;
    height: 150px;
    width: 150px;
    top: -75px;
    z-index: 0;
    position: absolute;

    @include media-breakpoint-up(lg) {
      height: 300px;
      width: 300px;
      top: -150px;
    }
  }
}
.leaf-right-before {
  position: relative;

  &:after {
    content: '';
    background:url(../images/leaf.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(230deg);
    right: 30px;
    bottom: -75px;
    height: 150px;
    width: 150px;
    z-index: 0;
    position: absolute;

    @include media-breakpoint-up(lg) {
      height: 300px;
      width: 300px;
      z-index: 0;
    }
  }
}
.leaf-left-before-2{
  position: relative;

  &:before {
    content: '';
    background:url(../images/leaf.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(302deg);
    left: 30px;
    height: 150px;
    width: 150px;
    top: -75px;
    z-index: 0;
    position: absolute;

    @include media-breakpoint-up(lg) {
      height: 300px;
      width: 300px;
      top: -150px;
    }
  }
}
.leaf-bottom-left {
  position: relative;

  &:before {
    content: '';
    background:url(../images/leaf.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(302deg);
    left: 30px;
    height: 150px;
    width: 150px;
    top: calc(100% - 300px);
    z-index: 0;
    position: absolute;

    @include media-breakpoint-up(lg) {
      height: 300px;
      width: 300px;
      top: calc(100% - 300px);
    }
  }
}
.leaf-right-after-sidebar {
  position: relative;
  height: 150px;
  margin-bottom: -75px;
  z-index: 2;

  @include media-breakpoint-up(lg){
    height: 300px;
    margin-bottom: -150px;
  }
  &:before {
    content: '';
    background:url(../images/leaf.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(302deg);
    right: 30px;
    height: 150px;
    width: 150px;
    top: 0px;
    z-index: 1;
    position: absolute;

    @include media-breakpoint-up(lg) {
      height: 300px;
      width: 300px;
      top: 0px;
    }
  }
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.box-shadow {
  box-shadow: 0px 13px 46px #00000029;
}
.border-radius-20 {
  border-radius: 20px;
}
.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
  @include media-breakpoint-up(md) {
    min-height: 33vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}
.negative-top-lg {
  @include media-breakpoint-up(lg) {
    margin-top: -250px;
  }
}
.home .negative-top-lg {
  @include media-breakpoint-up(lg) {
    margin-top: -150px!important;
  }
}
.negative-top-md {
  @include media-breakpoint-up(md) {
    margin-top: -100px;
  }
}
.negative-bottom {
  @include media-breakpoint-up(lg) {
    margin-bottom: -150px;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 30px;
  }
}
.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}
.background-light-lg {
	@include media-breakpoint-up(lg) {
		background-color: $light;
	}
}