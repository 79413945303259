.rpb-hero {
  z-index: 0;
  position: relative;

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  // Background Gallery
  .rpb-hero-gallery {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .rpb-gallery-single {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      z-index: 0;
      opacity: 0;
      animation: bg-fade-out 9s infinite both;
      &:nth-child(2) {
        animation-delay:3s;
      }
      &:nth-child(3) {
        animation-delay:6s;
      }
    }
  }
  // Video Background
  .absolute-slider,
  .hero-bkg-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #101010;

    .slick-list,
    .slick-slide,
    .slick-track {
      width: 100%;
      height: 100%;

      * {
        height: 100%;
      }
    }
  }
  /** embed video styles**/
  .hero-video {
    overflow: hidden !important;

    .autoplay-video {
      position: absolute;
      left: 50%;
      bottom: 0;
      -webkit-transform: translate(-50%, 0%) !important;
      -o-transform: translate(-50%, 0%) !important;
      transform: translate(-50%, 0%) !important;
      width: auto;
      min-width: 100%;
      min-height: 100%;
    }
    &.video-overlay:before {
      content: '';
      /*background: rgb(80 65 50 / 50%);*/
      background:rgb(53 50 47 / 75%);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
  /** youtube video styles **/
  .inline-YTPlayer {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .hero-bkg-youtube-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Slider Styles
.rpb-hero-slider {
  .splide__slider {
    .splide__track {
      .splide__list {
        .splide__slide {
          padding-right: 4rem;
          padding-left: 4rem;
        }
      }
    }
  }
}

//hero fixed content
.custom-grid {
  grid-auto-rows: 1fr;
  display: grid;
}
.h-33 {
  height: 100%;
  display: flex;
}
.leaf-under {
  transform: rotate(270deg);
  height: 150px;
  width: 150px;
  right: 23%;
  top: 50%;
  z-index: 0;

  @include media-breakpoint-up(lg) {
    height: 300px;
    width: 300px;
  }
}
.leaf-over {
  transform: rotate(335deg);
  height: 150px;
  width: 150px;
  right: 0px;
  top: 350px;
  z-index: 2;

  @include media-breakpoint-up(md) {
    top: 350px;
    height: 250px;
    width: 250px;
  }
  @include media-breakpoint-up(lg) {
    top:20%;
    height: 300px;
    width: 300px;
  }
}
.fixed-rwi {
  position: fixed!important;
  display: grid!important;
  height: 100%;
  width: 33%;
  max-width: 686px!important;
  right: 0px;
  z-index: 1;
  grid-auto-rows: 1fr;
}