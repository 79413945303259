.rpb-cards {
  .card {
    border:0;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
      margin-top: 15px;
      @include media-breakpoint-up(lg) {
        font-size: 1.15rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
      }
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left:3rem;
        margin-right:3rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
}
.bkg-zoom {
  margin-top: 15px;
  background-size: cover!important;
  background-position: center!important;
  transition: all .5s ease-out!important;
  transform: translateY(0px);
  * {
    z-index: 1;
  }
  &:before {
    content: '';
    position: absolute;
    height:100%;
    width: 100%;
    background-color: rgba(226, 184, 109,0);
    transition: all .5s ease-out!important;
    border-radius: 20px;
    z-index: 0px;
  }
  &:hover {
    transform: translateY(-10px);
    transition: all .5s ease-out!important;

    &:before {
      background-color: rgba(226, 184, 109,.65);
      transition: all .5s ease-out!important;
    }
  }
}
.plus-link {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  color: #fff;
  border: solid 2px #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease-out;

  &::before {
    content: '+';
    font-size: 3em;
    font-weight: 400;
    font-family: $body-font;
    transition: all .5s ease-out;
  }
  &:hover {
    background: #fff;
    color: $brand-primary;
    transition: all .5s ease-out;
  }
}
.rpb-cards .plus-link {
  margin: 200px auto 30px;
}