.rpb-customer-reviews {
  .reviews-heading-icon {
    font-size:60px;
  }
  .customer-reviews-slider {
    .splide__slider {
      .splide__track {
        @include media-breakpoint-up(lg) {
          margin-left:3rem;
          margin-right:3rem;
        }
      }
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
          @include media-breakpoint-up(lg) {
            left:25px;
          }
        }
        .splide__arrow--next {
          right:0;
          @include media-breakpoint-up(lg) {
            right:25px;
          }
        }
        .splide__arrow {
          background: $brand-primary;
          svg {
            color: white;
          }
        }
      }
    }
  }
  &.reviews-style-1 {
    .customer-reviews-slider {
      text-align:center;
      .splide__slider {
        .rpb-slider-arrows {
          .splide__arrow {
            background: $brand-primary;
            svg {
              color: white;
            }
            @include media-breakpoint-down(lg) {
              position:relative;
              transform:none;
              display:inline-block;
            }
            .splide__arrow--prev {
              svg {
                transform:none;
                @include media-breakpoint-down(lg) {
                  margin-top:6px;
                  left:unset;
                }
              }
            }
            .splide__arrow--next {
              svg {
                transform:none;
                @include media-breakpoint-down(lg) {
                  margin-top:6px;
                  right:unset;
                }
              }
            }
          }
        }
      }
    }
  }
  &.reviews-style-2 {
    .customer-reviews-slider {
      text-align:center;
      @include media-breakpoint-up(lg) {
        text-align:left;
      }
      .splide__slider {
        .rpb-slider-arrows {
          .splide__arrow {
            background: transparent;
            svg {
              color: $gray;
            }
          }
          .splide__arrow--prev {
            left:0;
            @include media-breakpoint-up(lg) {
              left:0;
            }
          }
          .splide__arrow--next {
            right:0;
            @include media-breakpoint-up(lg) {
              right:0;
            }
          }
        }
      }
    }
    .location-single-review-name {
      font-size:20px;
      @include media-breakpoint-up(lg) {
        font-size:26px;
      }
    }
  }
  .location-single-review {
    position: relative;

    .location-single-review-icon {
      font-size: 35px;
      margin-bottom: 1rem;
      height: 70px;
      width: 70px;
      border-radius: 70px;
      background: transparent linear-gradient(180deg, #8FDB8A 0%, #61A34B 100%) 0% 0% no-repeat padding-box;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -35px;
    }
    .location-single-review-stars {
      margin-bottom:1rem;
      i,svg {
        color:#F78F18;
      }
    }
    .location-single-review-content {
      font-style: italic;
      margin-bottom: 1rem;
    }
    .location-single-review-name {
      span {
        padding: 10px 30px;
        border-radius: 999px;
        text-transform: uppercase;
        color: white;
      }
    }
  }
  .customer-reviews-form {
    .gform_legacy_markup_wrapper ul.gform_fields li.gfield {
      padding-right:0;
    }
    .gform_legacy_markup_wrapper .gform_footer input.button, .gform_legacy_markup_wrapper .gform_footer input[type=submit], .gform_legacy_markup_wrapper .gform_page_footer input.button, .gform_legacy_markup_wrapper .gform_page_footer input[type=submit] {
      background:white;
      border:4px solid white;
      color:$brand-primary;
      &:hover {
        background:lighten($brand-primary,15%);
        border:4px solid lighten($brand-primary,15%);
        color:white;
      }
    }
  }
}
.home-review {
  border-radius:20px;
  background:rgba(255,255,255,0.15);
  color: #fff;
  border: solid 1px $accent-2;
}