.daily-deals {
	.single-deal {
		transition:all 350ms ease-in-out;
		margin-bottom:15px;
		&:last-of-type {
			margin-bottom:0;
		}
		&:hover {
			box-shadow:0;

			.single-deal-button {
				opacity:1;
			}
			.deal-fine-print {
				color:white;
			}
		}
		p {
			margin-bottom:0;
		}
	}
}